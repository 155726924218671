<template>
  <v-card>
    <v-toolbar
      dark
      color="primary"
      dense
      class="mb-5"
    >
      <v-btn
        icon
        dark
        @click="close"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Vagas Internas</v-toolbar-title>
      <v-spacer />
    </v-toolbar>

    <v-form ref="form">
      <v-row
        justify="start"
        no-gutters
        class="pa-2"
      >
        <v-col
          class="d-flex"
          lg="2"
          md="2"
        >
          <v-checkbox
            v-model="job.status"
            label="Ativo?"
          />
        </v-col>
      </v-row>

      <v-row
        justify="start"
        no-gutters
        class="pa-2 mt-0"
      >
        <v-text-field
          v-model="job.title"
          :rules="$rules.required"
          required
          outlined
          dense
          label="Titulo *"
        />
      </v-row>

      <v-row
        justify="start"
        no-gutters
        class="pa-2 mt-0"
      >
        <v-col
          class="d-flex pr-5"
          lg="4"
          md="12"
          sm="12"
        >
          <v-text-field
            v-model="job.author"
            :rules="$rules.required"
            required
            outlined
            dense
            label="Autor *"
            disabled
          />
        </v-col>

        <v-col
          class="d-flex pr-5"
          lg="4"
          md="12"
          sm="12"
        >
          <v-text-field
            v-model="job.city"
            :rules="$rules.required"
            required
            outlined
            dense
            label="Cidade *"
          />
        </v-col>

        <v-col
          class="d-flex pr-5"
          lg="4"
          md="12"
          sm="12"
        >
          <v-text-field
            v-model="job.sector"
            :rules="$rules.required"
            required
            outlined
            dense
            label="Setor *"
          />
        </v-col>
      </v-row>

      <v-row
        justify="start"
        no-gutters
        class="pa-2 mt-0"
      >
        <v-col class="pr-5">
          <kosmo-editor
            v-model="job.description"
            :max-length="4000"
            :custom-toolbar="toolbarProp"
            label="Descrição da Vaga *"
          />
        </v-col>
      </v-row>

      <v-row
        justify="start"
        no-gutters
        class="pa-2 mt-0"
      >
        <v-col class="pr-5">
          <kosmo-editor
            v-model="job.need"
            :max-length="4000"
            :custom-toolbar="toolbarProp"
            label="Qualificações Necessárias *"
          />
        </v-col>
      </v-row>

      <v-row
        justify="start"
        no-gutters
        class="pa-2 mt-0"
      >
        <v-col class="pr-5">
          <kosmo-editor
            v-model="job.must"
            :max-length="4000"
            :custom-toolbar="toolbarProp"
            label="Qualificações Desejáveis *"
          />
        </v-col>
      </v-row>
    </v-form>

    <v-row
      class="mt-10"
      justify="center"
      no-gutters
    >
      <v-card-actions>
        <v-btn
          color="secondary"
          @click="close"
        >
          Cancelar
        </v-btn>
        <v-btn
          :color="loading ? 'accent' : 'primary'"
          :loading="loading"
          @click="submit"
        >
          {{ loading ? "Loading..." : "Salvar" }}
        </v-btn>
      </v-card-actions>
    </v-row>
  </v-card>
</template>

<script>
import Jobs from '../../../services/jobs.service';
import store from '../../../store/index';

const MAX_CHARACTER_LIMIT = 4000;

export default {
  name: 'JobsForm',
  props: {
    populateWith: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      job: {},
      user: '',
      toolbarProp: [
        [{ font: [] }],
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ['bold', 'italic', 'underline', 'strike'],
        [
          { align: '' },
          { align: 'center' },
          { align: 'right' },
          { align: 'justify' },
        ],
        ['code-block'],
        [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
        [{ script: 'sub' }, { script: 'super' }],
        [{ color: [] }, { background: [] }],
        [{ direction: 'rtl' }],
        ['clean'],
      ],
    };
  },
  created() {
    this.user = store.getters['auth/userName'];
    this.job = this.populateWith.id
      ? { ...this.populateWith }
      : { author: this.user };
  },
  methods: {
    close() {
      this.$emit('close-dialog');
    },

    updateJobList() {
      this.$emit('update-job-list');
    },

    async submit() {
      const exceededLimits = this.job.description.length > MAX_CHARACTER_LIMIT
        || this.job.need.length > MAX_CHARACTER_LIMIT
        || this.job.must.length > MAX_CHARACTER_LIMIT;

      if (exceededLimits) {
        this.$toast.error('Limite de caracteres atingido no campo de texto!');
        return;
      }

      if (!this.$refs.form.validate()) {
        this.$toast.error('Verifique os campos e tente novamente');
        return;
      }

      try {
        this.loading = true;
        this.job.status = this.job.status ? 1 : 0;
        await Jobs.createJobs(this.job);
        this.clearForm();
        this.$toast.success('Formulário salvo com sucesso');
        this.updateJobList();
        this.close();
      } catch (e) {
        this.$handleHttpError(e);
      } finally {
        this.loading = false;
      }
    },

    clearForm() {
      this.$refs.form.reset();
      this.job = {
        description: null,
        author: null,
        status: null,
        title: null,
        city: null,
        sector: null,
        need: null,
        must: null,
        active: false,
      };
      this.$refs.form.resetValidation();
    },
  },
};
</script>
